<template>
  <!--<PageHeader HeaderText="Create"/>-->
  <login />
  <Start />

</template>
<script>
//import PageHeader from "@/components/PageHeader.vue";
import login from "@/components/login.vue";
import Start from "./Start.vue";
export default {
  name: "Create",
  components: {
    login,
    Start,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
