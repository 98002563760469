<template>
<div @click="$emit('clickedEntry')" class="flex p-2 items-center">
  <div class="basis-1/6 max-h-14 self-stretch"><img src="@/assets/Oval.png" class="h-full"></div>
  <div class="basis-5/6 max-h-14">
    <p class="contact-name">{{ Name }}</p>
    <p class="contact-email">{{ Email }} | {{ Phone }}</p>
  </div>
</div>
<hr class="my-2 h-px w-11/12 mx-auto">

</template>

<script>
export default {
  name: "PeopleEntry",
  emits: ["clickedEntry"],
  props: {
    Name: String,
    Email: String,
    Phone: String,
    uid: String


  },

}
</script>

