
<template>

    <div class="text-center mx-auto bg-dgreen max-w-sm my-3 py-4 px-2">
        <p class="text-white text-3xl font-header">{{ HeaderText }}</p>
    </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    HeaderText: String
  }
}
</script>
