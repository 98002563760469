<template>

<div v-show="notStandalone">

  <div class="flex flex-wrap  welcome">
      <p class="text-center mx-5 my-1 text-2xl">
       How to Install:<br /><video class="max-h-[32rem] mx-auto" src="https://inheartenvideos.blob.core.windows.net/videos/VvSHCd1uuPd.mp4" controls preload="auto"></video>
      </p>
  </div>
</div>
<div v-if="showSend" class="mb-36 columns-2">
<div v-for="item in messageTypes" :key="item.id">
<div @click="selectType(item)">
      <img class="pl-1 pb-1" :src="item">


</div>
</div>
<hr class="my-2 h-px w-11/12 mx-auto">
</div>
<div v-show="!videoIsRecorded&&!notStandalone&&!recordingAudio">
  <div class="flex flex-wrap  welcome">
      <p class="text-center mx-5 my-5 text-2xl">
        Tap <strong>Start</strong> to begin your video or voice message.
      </p>
  </div>
  <div class="flex flex-wrap ">
    
    <label class="bg-[#ffa237] border-black border-2 text-2xl font-bold rounded-2xl mx-auto px-4 py-3 drop-shadow-3xl">
      <span style="font-size: 1.2em;">Start</span> Video Message<input @change="postRecord( $event )" type="file" accept="video/*" capture="user" hidden>
    </label>
  </div>
  <div class="flex flex-wrap pt-8">
    <div @click="audioMessage" class="bg-[#ffa237] border-black border-2 text-2xl font-bold rounded-2xl mx-auto px-4 py-3 drop-shadow-3xl">
      <span style="font-size: 1.2em;">Start</span> Audio Message
    </div>
  </div>
  <div class="flex flex-wrap  welcome">
    <p class="text-center mx-5 my-5 text-2xl">
      Next, tap the red record button to begin recording.
    </p>
    </div>
</div>
<div class="mx-auto" v-show="recordingAudio">
  <div class="flex flex-wrap  welcome" v-if="!audioFinished&&!currentlyRecording">
      <p class="text-center mx-5 my-5 text-2xl">  
        Tap the microphone icon to begin recording audio.
      </p>
  </div>
  <audio style="font-size: 110%;" id="myVideo" class="video-js vjs-default-skin mx-auto" playsinline></audio>
  <div v-if="currentlyRecording" @click="stopIt()" class="block bg-red-600 border-black border-2 text-2xl text-white font-bold rounded-2xl mx-auto px-4 py-2 mt-5 drop-shadow-3xl w-1/2 text-center">
      Stop Recording
  </div>
  <div v-if="audioFinished" @click="postAudio()" class="block bg-[#3f7a81] border-black border-2 text-2xl text-white font-bold rounded-2xl mx-auto px-4 py-2 mt-5 drop-shadow-3xl w-1/4 text-center">
      Next
  </div>
  
</div>
<div v-if="videoIsRecorded">
  <div v-if="!showForm && !showContactList && !showSend">
    <div @click="$event=> backToStart()" class="block bg-[#3f7a81] border-black border-2 text-xl text-white font-bold rounded-2xl px-4 py-2 ml-12 drop-shadow-3xl w-1/5 text-center">
      Back
    </div>
  </div>
  <div v-if="showForm || showContactList">
    <div @click="backFromContact()" class="block bg-[#3f7a81] border-black border-2 text-xl text-white font-bold rounded-2xl px-4 py-2 ml-12 drop-shadow-3xl w-1/5 text-center">
      Back
    </div>
  </div>
  <div v-if="!showForm && !showContactList && !showSend" class="flex flex-wrap my-8">
    <div @click="newContact()" class="block bg-[#ffa237] border-black border-2 text-2xl font-bold rounded-2xl mx-auto px-4 py-3 drop-shadow-3xl my-4 w-2/3 text-center">New Contact</div>
    <div @click="existingContact()" class="bg-[#ffa237] border-black border-2 text-2xl font-bold rounded-2xl mx-auto px-4 py-3 drop-shadow-3xl my-4 w-2/3 text-center">Existing Contact</div>

  </div>
</div>
<div v-if="showForm" class="mx-auto text-center pt-4 text-xl space-y-2">

  <input class="mx-auto w-10/12 p-2 rounded-md bg-stone-200 border-stone-700 border shadow-inner" v-model="destinationName" placeholder="First and Last Name" />
  <input class="mx-auto w-10/12 p-2 rounded-md bg-stone-200 border-stone-700 border shadow-inner" v-model="destinationEmail" placeholder="Email Address" />
  <input class="mx-auto w-10/12 p-2 rounded-md bg-stone-200 border-stone-700 border shadow-inner" v-model="destinationPhone" placeholder="Phone Number" />
  <div v-if="!showFinal" @click="nextFromContact()" class="block bg-[#3f7a81] border-black border-2 text-xl text-white font-bold rounded-2xl px-4 py-2 drop-shadow-3xl w-1/4 mx-auto text-center">
      Next
  </div>
</div>
<div class="mb-36" v-if="showContactList">
  <hr class="my-2 h-px w-11/12 mx-auto mt-4">
  <PeopleEntry v-for="contact in people" :key="contact.id" :Name="contact.name" :Email="contact.email" :Phone="contact.phone" @clicked-entry="enterContact( contact.name, contact.email, contact.phone )"/>

</div>
<div class="mx-auto pt-4 text-center" v-if="showFinal">

  <input class="hidden w-10/12 p-2 rounded-md bg-stone-200 border-stone-700 border shadow-inner" v-model="messageBody" placeholder="If you would like to leave text along with your message, tap here to enter it."/>
  <div v-if="!isSending" @click="uploadVideo()" class="bg-[#ffa237] border-black border-2 text-2xl font-bold rounded-2xl mx-auto px-4 py-3 drop-shadow-3xl my-4 w-2/3 text-center">Send Message</div>
  <div v-if="!isSending" @click="backToStart()" class="bg-red-600 border-black border-2 text-2xl font-bold rounded-2xl mx-auto px-4 py-3 drop-shadow-3xl my-4 w-2/3 text-center">Start Over</div>

  <div v-if="isSending" role="status" class="mx-auto text-center">
    <svg aria-hidden="true" class="mx-auto w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>

</div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import PeopleEntry from '@/pages/People/PeopleEntry.vue';
import 'video.js/dist/video-js.css'
import 'videojs-record/dist/css/videojs.record.css'
import videojs from 'video.js'

import 'webrtc-adapter'
import RecordRTC from 'recordrtc'

// the following imports are only needed when you're recording
// audio-only using the videojs-wavesurfer plugin

import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';
WaveSurfer.microphone = MicrophonePlugin;

// register videojs-wavesurfer plugin
import videojs_wavesurfer_css from 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css';
import Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer.js';


import Record from 'videojs-record/dist/videojs.record.js'

WaveSurfer.microphone = MicrophonePlugin;

var isSafari = true;
var isEdge = /Edge/.test(navigator.userAgent);
var isOpera = !!window.opera || navigator.userAgent.indexOf('OPR/') !== -1;

function applyAudioWorkaround() {
    if (isSafari || isEdge) {
        if (isSafari && window.MediaRecorder !== undefined) {
            // this version of Safari has MediaRecorder
            // but use the only supported mime type
            options.plugins.record.audioMimeType = 'audio/mp4';
        } else {
            // support recording in safari 11/12
            // see https://github.com/collab-project/videojs-record/issues/295
            options.plugins.record.audioRecorderType = StereoAudioRecorder;
            options.plugins.record.audioSampleRate = 44100;
            options.plugins.record.audioBufferSize = 4096;
            options.plugins.record.audioChannels = 2;
        }

        console.log('applied audio workarounds for this browser');
    }
}

function applyVideoWorkaround() {
    // use correct video mimetype for opera
    if (isOpera) {
        options.plugins.record.videoMimeType = 'video/webm\;codecs=vp8'; // or vp9
    }
}

function applyScreenWorkaround() {
    // Polyfill in Firefox.
    // See https://blog.mozilla.org/webrtc/getdisplaymedia-now-available-in-adapter-js/
    if (adapter.browserDetails.browser == 'firefox') {
        adapter.browserShim.shimGetDisplayMedia(window, 'screen');
    }
}
export default {
  name: "Start",
  components: {
    PeopleEntry,
  },
  methods: {

    postAudio(){
      this.recordingAudio = false;
      this.videoIsRecorded = true;
    },
    postRecord(event){
      this.$auth0.checkSession()
    
      if (this.$auth0.isAuthenticated.value == false){
        console.log("it is not!")
        this.$auth0.loginWithPopup();      
      }
      
      this.videoIsRecorded = true;
      this.file = event.target.files[0];


    },
    backToStart(){
      if(window.confirm("This will erase your current recording. Are you sure you want to go back?")){
        this.videoIsRecorded = false;
        this.recordingAudio = false;
        this.file = '';
        this.destinationName = '';
        this.destinationEmail = '';
        this.destinationPhone = '';
        this.messageBody = '';
        this.videoIsRecorded = false;
        this.showForm = false;
        this.showContactList = false;
        this.showSend = false;
        this.showFinal = false;
      }
     
    },
    audioMessage(){
       /* eslint-disable no-console */
       this.player = videojs('#myVideo', this.options, () => {
                // print version information at startup
                var msg = 'Using video.js ' + videojs.VERSION +
                    ' with videojs-record ' + videojs.getPluginVersion('record') +
                    ' and recordrtc ' + RecordRTC.version;
                videojs.log(msg);
            });
            const player = this.player;

            // device is ready
            this.player.on('deviceReady', () => {
                console.log('device is ready!');
                this.player.record().start();
            });

            // user clicked the record button and started recording
            this.player.on('startRecord', () => {
                console.log('started recording!');
                this.player.record().surfer.surfer.backend.ac.resume()
                this.currentlyRecording = true;
            });

            // user completed recording and stream is available
            this.player.on('finishRecord', () => {
                // the blob object contains the recorded data that
                // can be downloaded by the user, stored on server etc.
                console.log('finished recording: ', this.player.recordedData);
                this.file = this.player.recordedData;
                this.audioFinished = true;
                this.currentlyRecording = false;
            });

            // error handling
            this.player.on('error', (element, error) => {
                console.warn(error);
            });

            this.player.on('deviceError', () => {
                console.error('device error:', this.player.deviceErrorCode);
            });
      this.recordingAudio = true;
    },
    newContact(){
      console.log("new one!")
      this.showForm = true;
      //this.showSend = true;

    },
    stopIt(){
      this.player.record().stop();
    },
    existingContact(){
      this.showContactList = true;
      //this.showForm = true;

    },
    backFromContact(){
      this.showForm = false;
      this.showSend = false;
      this.showContactList = false;
    },
    enterContact(name, email, phone){
      this.showForm = true;
      this.destinationName = name;
      this.destinationEmail = email;
      this.destinationPhone = phone;
      this.showContactList = false;
      //this.showSend = true;
    },
    nextFromContact(){
      this.showForm = false;
      this.showContactList = false;
      this.showSend = true;
    },
    selectType(type){
      this.messageBody = type;
      this.showSend = false;
      this.showForm = true;
      this.showFinal = true;
    },
    uploadVideo(){
      var fileName = Date.now() + '.mp4';
      const renamedFile = new File([this.file], fileName);
      let videoData = new FormData();
      videoData.append('file', renamedFile);
      this.videoUrl = "https://files.mundofuster.com/files/" + fileName; //this should work
      this.isSending = true;
      axios.post( 'https://files.mundofuster.com/upload',
      videoData,
      {}
      ).then(response => {
        console.log('it uploaded');
        
        this.sendMessage(this.file.name)
      })
    },
    sendMessage(name){
      let formData = new FormData();

      if (this.destinationEmail === '') {
        formData.append('sent_to', "dakota.list@yahoo.com");
      }
      else{
        formData.append('sent_to', this.destinationEmail);
      }
      formData.append('to_phone', this.destinationPhone);
      formData.append('video_url', this.videoUrl)
      if(this.messageBody === ''){
        formData.append('body', this.bodyPrefix);
      }
      else{
        formData.append('body', this.bodyPrefix+" "+this.messageBody);
      }

      formData.append('reply_to', 'info@inhearten.com');
      formData.append('from_name', 'In Hearten');

      //formData.append('video', this.file);
      
      const self = this;

      axios.post( 'https://api.inhearten.com/api/emails/message/',
      {
          headers: {
            'x-ms-client-principal': 'auth0|66aa88b053b0d5c396fa27e2'

          }
        },
        formData,
        {
       
        }
      ).then(function(){
        console.log('success');
        self.isSending = false;
        window.alert('Your message has been sent!');
        self.file = '';
        self.destinationName = '';
        self.destinationEmail = '';
        self.destinationPhone = '';
        self.messageBody = '';
        self.videoIsRecorded = false;
        self.showForm = false;
        self.showContactList = false;
        self.showSend = false;
        self.showFinal = false;


      })
      .catch(function (error) {
        window.alert("An error occured. Please try again.");
        self.isSending = false;
        if (error.response) {
      
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }

  });

    },
  },
  data(){
    return {
      file: '',
      destinationName: '',
      destinationEmail: '',
      destinationPhone: '',
      messageBody: '',
      videoIsRecorded: false,
      showForm: false,
      showContactList: false,
      showSend: false,
      currentlyRecording: false,
      showFinal: false,
      isSending: false,
      notStandalone: false,
      recordingAudio: false,
      audioFinished: false,
      videoUrl: '',
      people: [],
      userData: [],
      messageTypes: ['https://inhearten.com/images/card/congratulations.jpg', 'https://inhearten.com/images/card/checking.jpg', 'https://inhearten.com/images/card/anniversary.jpg', 'https://inhearten.com/images/card/birthday.jpg', 'https://inhearten.com/images/card/easter.jpg', 'https://inhearten.com/images/card/just.jpg', 'https://inhearten.com/images/card/shalom.jpg', 'https://inhearten.com/images/card/shower.jpg', 'https://inhearten.com/images/card/thank.jpg', 'https://inhearten.com/images/card/to.jpg', 'https://inhearten.com/images/card/with.jpg'],
      currentMode: 'video',
      bodyPrefix: ' ',
      player: '',
      options: {
        controls: true,
        controlBar:{
          fullscreenToggle: false,
          volumePanel: false,
        },
        width: 300,
        height: 500,
        fluid: true,
        plugins: {
          wavesurfer: {
              backend: 'WebAudio',
              waveColor: '#36393b',
              backgroundColor: '#407a81',
              progressColor: 'black',
              displayMilliseconds: false,
              debug: true,
              cursorWidth: 1,
              barMinHeight: 10,
              hideScrollbar: true,
              plugins: [
                  // enable microphone plugin
                  WaveSurfer.microphone.create({
                      bufferSize: 4096,
                      numberOfInputChannels: 1,
                      numberOfOutputChannels: 1,
                      constraints: {
                          video: false,
                          audio: true
                      }
                  })
              ]
          },
          record: {
              audio: true,
              video: false,
              maxLength: 600,
              displayMilliseconds: false,
              debug: true
          }
        }
                }
    }
  },
  beforeUnmount() {
      if (this.player) {
          this.player.dispose();
      }
  },
  created(){

    this.$auth0.checkSession()
    

   
     



  }

}
</script>

<style>

</style>