<template>
    <PageHeader HeaderText="Settings" />
    <UserProfile />
    <logout />
    <p>Version 3.5 build 3923a</p>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import UserProfile from "@/components/UserProfile.vue";
import logout from "@/components/logout.vue";
export default {
  name: "Settings",
  components: {
    PageHeader,
    UserProfile,
    logout,
  },
  data() {
    return {
     userInfo: {
       type: Object,
       default() {},
     },
   };
  },
  methods: {
  async getUserInfo() {
    try {
      const response = await fetch('/.auth/me');
      const payload = await response.json();
      const { clientPrincipal } = payload;
      return clientPrincipal;
    } catch (error) {
      console.error('No profile could be found');
      return undefined;
    }
  },
},
}
</script>