<template>
    <PageHeader HeaderText="People" />

    <div v-if="currentMode != 'AddContact'" class="mx-auto pt-4 text-center">
      <div @click="currentMode = 'AddContact'" class="bg-[#ffa237] border-black border-2 text-2xl font-bold rounded-2xl mx-auto px-1 py-2 drop-shadow-3xl my-3 w-1/2 text-center">Add New</div>
    </div>
  
   <component :is="currentMode"></component>

</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import PeopleListing from "./PeopleListing.vue"
import AddContact from "./AddContact.vue"
export default {
  name: "People",
  components: {
    PageHeader,
    PeopleListing,
    AddContact
  },
  data (){
    return{
      currentMode: 'PeopleListing'
    }
  }

  }

</script>