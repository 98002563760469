<template>
    <div @click="$emit('clickedEntry')" class="flex p-2 items-center">
      <div v-if="Replied" class="basis-1/6 max-h-14 self-stretch"><img src="@/assets/Oval-green.png" class="h-full"></div>
      <div v-else class="basis-1/6 max-h-14 self-stretch"><img src="@/assets/Oval.png" class="h-full"></div>
      <div class="basis-5/6 max-h-50">
        <p class="contact-name">{{ Name }}</p>
        <p class="contact-email">{{ Details }}</p>
      </div>
    </div>
    <hr class="my-2 h-px w-11/12 mx-auto">
    
    </template>
    
    <script>
    export default {
      name: "SentEntry",
      emits: ["clickedEntry"],
      props: {
        Name: String,
        Details: String,
        uid: String,
        Replied: Boolean
    
    
      },
    
    }
    </script>
    
    