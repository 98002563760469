<template>
  <div class="max-w-[60%] mx-auto py-2">
      <img src="./assets/logo.png" alt="Inhearten Logo">
  </div>



  <div class="w-full fixed bottom-0 bg-green h-40 rounded-tr-xl">
    <div class="w-full bg-[#ebfbfd] h-14 rounded-bl-full">
      <div class="w-full grid grid-cols-4 fixed pt-4 pb-6 bottom-0 bg-green text-center">
        <div class="text-white" v-for="tab in tabs" :key="tab" @click="currentTab = tab"> 
          <font-awesome-icon v-if="tab === 'Create'" class="text-4xl" icon="fa-solid fa-video" />
          <font-awesome-icon v-else-if="tab === 'People'" class="text-4xl" icon="fa-solid fa-address-book" />
          <font-awesome-icon v-else-if="tab === 'Sent'" class="text-4xl" icon="fa-solid fa-envelope-circle-check" />
          <font-awesome-icon v-else-if="tab === 'Settings'" class="text-4xl" icon="fa-solid fa-bars" />
          <div>{{ tab }}</div>
        </div>
      </div>
    </div>
  </div>
  <component :is="currentTab"></component>


</template>

<script>
import Create from "@/pages/Create/Create.vue";
import People from "@/pages/People/People.vue"; 
import Sent from "@/pages/Sent/Sent.vue";
import Settings from "@/pages/Settings/Settings.vue";
import PageHeader from "./components/PageHeader.vue";
export default {
  name: "App",
  components: {
    Create,
    People,
    Sent,
    Settings,
    PageHeader,
  },
  data (){
    
    return {
      currentTab: 'Create',
      tabs: ['Create', 'People', 'Sent', 'Settings'],
      user: this.$auth0.user,
      isAuthenticated: this.$auth0.isAuthenticated,
      isLoading: this.$auth0.isLoading
    }
  },
  methods: {
        login() {
          this.$auth0.loginWithRedirect();
        }
      }
}; 

</script>


