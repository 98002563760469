<template>
    <PageHeader HeaderText="Sent" />
    <div class="pt-4 mb-36">
      <hr class="my-2 h-px w-11/12 mx-auto">
      <SentEntry v-for="message in messages" :key="message.id" :Name="message.sent_to" :Details="message.reply_body" :Replied="message.isReplied"/>
    </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import SentEntry from "./SentEntry.vue";
import axios from 'axios';
export default {
  data() {
    return{
      messages: []

    }
  },
  name: "Sent",
  components: {
    PageHeader,
    SentEntry,
  },
  methods: {
    loadMessages(){
        axios.get('/api/emails/sent/')
        .then((response) => {
        this.messages = response.data

        for (const message in this.messages){
          var sentTimestamp = this.messages[message].video_url.replace(/[^0-9]/g, '');
          sentTimestamp = sentTimestamp.substring(0, sentTimestamp.length - 1);
          var sentDateTime = new Date(parseInt(sentTimestamp));
          var sentDateString = sentDateTime.toLocaleDateString("en-US") + ' ' + sentDateTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});

          if (!this.messages[message].reply_body){
            this.messages[message].reply_body = sentDateString + ' - ' + this.messages[message].body;
            this.messages[message].isReplied = false;
          }
          else{
           this.messages[message].isReplied = true;
          }
          if (this.messages[message].sent_to == "dakota.list@yahoo.com") {
            this.messages[message].sent_to = this.messages[message].to_phone;
          }
         

        }  
        })
       
      }
  },
  created() {
    this.loadMessages()
  }
}
</script>