import "./assets/css/main.css"
import { createApp } from "vue";
import { createAuth0 } from '@auth0/auth0-vue';
import App from "./App.vue";
import "./registerServiceWorker";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faVideo } from '@fortawesome/free-solid-svg-icons'
import { faAddressBook } from '@fortawesome/free-solid-svg-icons'
import { faEnvelopeCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faPencil } from '@fortawesome/free-solid-svg-icons'


/* add icons to the library */
library.add(faVideo,faAddressBook,faEnvelopeCircleCheck,faBars,faPencil)

const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(
    createAuth0({
      domain: "dev-6aqbpn64rwet86ch.us.auth0.com",
      clientId: "ufcVCvfk0h7qeQxkeD1dsadu7FNPQbWA",
      authorizationParams: {
        redirect_uri: window.location.origin
      }
    })
  );
app.mount("#app");
